export * from "./apis";

export const fetchPost = ({ url, data }) => {
    return new Promise((resolve) => {
        fetch(url, {
            method: "POST",
            body: data ? JSON.stringify(data) : "",
        }).then((res) => {
            resolve(res.json());
        });
    });
};

export const fetchGet = ({ url }) => {
    return new Promise((resolve) => {
        fetch(url, {
            method: "GET",
        }).then((res) => {
            resolve(res.json());
        });
    });
};