<template>
  <div class="flex-col page">
    <div class="flex-col section_1">
      <div class="flex-col section_2">
        <template v-if="loadFinished">
          <div class="flex-col section_3">
            <div class="flex-col group">
              <div class="flex-row section_4">
                <div class="flex-row items-center">
                  <img alt="" src="../assets/img/logo-icon.png" class="image" />
                  <img
                    alt=""
                    src="../assets/img/logo-text.png"
                    class="image_1"
                  />
                </div>
                <svg
                  width="77px"
                  height="64px"
                  viewBox="0 0 77 64"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  class="image_2"
                >
                  <g
                    id="页面-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="ZLY"
                      transform="translate(-921.000000, -265.000000)"
                      stroke="#FFFFFF"
                      stroke-width="2"
                    >
                      <g
                        id="编组-4"
                        transform="translate(923.000000, 266.000000)"
                      >
                        <g
                          id="编组-3"
                          transform="translate(-0.347582, 0.344828)"
                        >
                          <line
                            x1="74"
                            y1="6.26443342e-13"
                            x2="10.2068966"
                            y2="61"
                            id="路径-10"
                          ></line>
                          <line
                            x1="63.7931034"
                            y1="6.26443342e-13"
                            x2="0"
                            y2="61"
                            id="路径-10备份"
                          ></line>
                        </g>
                        <g
                          id="编组-3"
                          transform="translate(36.652418, 30.844828) scale(-1, 1) translate(-36.652418, -30.844828) translate(-0.347582, 0.344828)"
                        >
                          <line
                            x1="74"
                            y1="6.26443342e-13"
                            x2="10.2068966"
                            y2="61"
                            id="路径-10"
                          ></line>
                          <line
                            x1="63.7931034"
                            y1="6.26443342e-13"
                            x2="0"
                            y2="61"
                            id="路径-10备份"
                          ></line>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <img alt="" :src="activityData.logoURI" class="image_3" />
              </div>
              <div class="text e-widget no-button generic-loader">
                <div
                  class=""
                  v-for="(item, index) in activityData.desc"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
            <span class="text_1">{{ activityData.dateTime }}</span>
          </div>
          <div class="flex-col items-center group_2">
            <div class="section_5 pc-website-href"></div>
            <div class="flex-row equal-division">
              <div class="flex-col equal-division-item">
                <div
                  class="flex-col items-center text-wrapper btn-item1"
                  @click="openParticipate"
                >
                  <span>Participate</span>
                </div>
                <div class="justify-center group_3 pc-website-href">
                  <img alt="" src="../assets/img/image_4.png" class="image_4" />
                  <span class="text_5"
                    ><a href="https://solv.finance" target="_blank"
                      >https://solv.finance</a
                    >
                  </span>
                </div>
              </div>
              <div class="flex-col equal-division-item equal-division-item_1">
                <div
                  class="flex-col items-center text-wrapper_1 btn-item1"
                  @click="openAnnouncement"
                >
                  <span>Announcement</span>
                </div>
                <div class="justify-center flex-row group_4 pc-website-href">
                  <img alt="" src="../assets/img/image_5.png" class="image_5" />
                  <span class="text_5"
                    ><a href="https://app.solv.finance" target="_blank"
                      >https://app.solv.finance</a
                    >
                  </span>
                </div>
              </div>
            </div>
            <div class="h5-website-href">
              <p class="text_5">
                <a href="https://solv.finance" target="_blank"
                  >https://solv.finance</a
                >
              </p>
              <p class="text_5">
                <a href="https://app.solv.finance" target="_blank"
                  >https://app.solv.finance</a
                >
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPost, getQuoth } from "../service";

export default {
  data() {
    return {
      activityData: {
        dateTime: "",
        desc: "",
        logoURI: "",
        name: "",
        participateURL: "",
        announcementURL: "",
      },
      loadFinished: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetchPost({
        url: getQuoth,
        data: { name: "Quoth" },
      });
      console.log("res ===> ", res);
      if (res.success) {
        this.activityData = res.result;
      }
      this.loadFinished = true;
    },
    openParticipate() {
      if (!this.activityData.participateURL) {
        return;
      }
      window.open(this.activityData.participateURL, "_blank");
      // window.open(
      //   "https://gleam.io/u4LdC/quoth-ivo-airdrop-whitelist-campaign",
      //   "_blank"
      // );
    },
    openAnnouncement() {
      if (!this.activityData.announcementURL) {
        return;
      }
      window.open(this.activityData.announcementURL, "_blank");
      // window.open(
      //   "https://solvprotocol.medium.com/voucher-airdrop-1-quoth-an-all-chain-nft-authentication-oracle-253caff07b8e",
      //   "_blank"
      // );
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: linear-gradient(
    180deg,
    rgba(1, 40, 196, 0.2) 0%,
    rgba(1, 40, 196, 0.2) 0%,
    rgba(0, 41, 155, 0.8) 99.96%,
    #000823 100%,
    #000823 100%
  );

  .section_1 {
    height: 100%;

    .section_2 {
      height: 100%;

      .section_3 {
        padding: 6vw 15.59vw 1vw;
        background: url("../assets/img/app-bg.png") no-repeat center
          center/cover;

        .group {
          color: rgb(255, 255, 255);
          font-size: 2.8vw;
          line-height: 4.06vw;

          .section_4 {
            padding: 1.5vw 2.6vw;
            background-image: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(0, 66, 255, 0.4) 100%,
              rgba(0, 66, 255, 0.4) 100%
            );
            border-radius: 6.25vw;
            justify-content: space-between;
            align-items: center;

            .image {
              width: 5.6vw;
              height: 5.6vw;
            }
            .image_1 {
              margin: 1.09vw 0 0.94vw 2.34vw;
              width: 12.88vw;
              // height: 5.63vw;
            }
            .image_2 {
              margin-left: 4.69vw;
              align-self: center;
              width: 3.91vw;
              height: 3.28vw;
            }
            .image_3 {
              margin-left: 6.25vw;
              width: 14vw;
              height: 5.5vw;
              margin-right: 3vw;
            }
          }
          .text {
            margin: 3.13vw 4.69vw 0 5vw;
            text-align: center;
            // text-transform: uppercase;
          }
        }
        .text_1 {
          margin-top: 1.88vw;
          align-self: center;
          color: rgb(255, 255, 255);
          font-size: 1.5vw;
          line-height: 2.19vw;
          white-space: nowrap;
        }
      }
      .group_2 {
        padding: 12vw 0 2.34vw;
        position: relative;
        .section_5 {
          background-image: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 14.32%,
            rgba(255, 255, 255, 0.2) 50.32%,
            rgba(255, 255, 255, 0) 85.89%,
            rgba(255, 255, 255, 0) 100%
          );
          border-radius: 1.41vw;
          width: 56.25vw;
          height: 2.97vw;
        }
        $footerPadding: 5.6vw;

        .equal-division {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          $itemWidth: 18.72vw;
          $btnWidth: 14.5vw;
          $btnHeight: 0.8vw;

          .equal-division-item {
            padding: 2.56vw 1.88vw 0 2.19vw;
            flex: 1 1 $itemWidth;
            width: $itemWidth;
            .text-wrapper {
              padding: $btnHeight 0;
              color: rgb(255, 255, 255);
              font-size: 1.25vw;
              font-weight: 600;
              line-height: 1.72vw;
              white-space: nowrap;
              border-radius: 1.56vw;
              background-image: url("../assets/img/btn_active.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: $btnWidth;
            }

            .group_3 {
              padding: #{$footerPadding} 0 1.56vw;
              color: rgb(255, 255, 255);
              font-size: 0.94vw;
              font-weight: 600;
              display: flex;
              align-items: center;
              white-space: nowrap;
              position: relative;
              .image_4 {
                width: 0.78vw;
                height: 0.78vw;
              }
            }
          }
          .equal-division-item_1 {
            padding: 2.56vw 1.88vw 0 2.19vw;
            flex: 1 1 $itemWidth;
            width: $itemWidth;
            .text-wrapper_1 {
              padding: $btnHeight 0;
              color: rgb(255, 255, 255);
              font-size: 1.25vw;
              font-weight: 600;
              line-height: 1.72vw;
              white-space: nowrap;
              border-radius: 1.56vw;
              background-image: url("../assets/img/btn_op.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              width: $btnWidth;
            }
            .not-click {
              cursor: not-allowed;
            }
            .group_4 {
              padding: #{$footerPadding} 0 1.56vw;
              color: rgb(255, 255, 255);
              font-size: 0.94vw;
              font-weight: 600;
              display: flex;
              align-items: center;
              white-space: nowrap;
              .image_5 {
                width: 0.78vw;
                height: 0.78vw;
              }
            }
          }
        }
        .text_5 {
          margin-left: 0.94vw;

          a {
            color: #fff;
            list-style: inside;
          }
        }
        .h5-website-href {
          display: none;
        }
      }
    }
  }
}

.btn-item1 {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

.btn-item1__op {
  background: transparent !important;
}

@media screen and (max-width: 800px) {
  .page {
    .section_1 {
      height: 100%;

      .section_2 {
        height: 100%;
      }
    }

    .section_3 {
      padding-left: 10% !important;
      padding-right: 10% !important;
      margin-top: 10vw !important;

      .group {
        .text {
          margin-top: 10vw !important;
        }
      }
      .text_1 {
        margin-top: 4vw !important;
      }
    }
    .section_4 {
      .image {
        width: 8vw !important;
        height: 8vw !important;
      }
      .image_1 {
        width: 18vw !important;
      }
      .image_2 {
        margin-left: 4.69vw;
        align-self: center;
        width: 5vw !important;
        height: 4vw !important;
      }
      .image_3 {
        width: 18vw !important;
      }
    }
    .group_2 {
      .equal-division {
        position: unset !important;
        transform: unset !important;
        margin-right: 20vw;
        margin-bottom: 8vw;
      }
      .equal-division-item_1 {
        position: absolute;
        margin-top: 12vw;
      }
      .btn-item1 {
        width: 36vw !important;
        padding: 3vw !important;
      }
    }
  }
  .pc-website-href {
    display: none !important;
  }
  .h5-website-href {
    margin-top: 10vw;
    display: block !important;
  }
}
</style>
